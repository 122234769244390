import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from '../context/userContext';

const ProtectedRoute = ({ children }) => {
    const { user } = useContext(UserContext);
    const location = useLocation();

    if (user) {
        // If user is logged in and tries to access the login or signup page, redirect to home
        if (location.pathname === '/login' || location.pathname === '/signup') {
            return <Navigate to="/home" replace />;
        }
    } else {
        // If user is not logged in and tries to access a protected route, redirect to login
        if (location.pathname === '/home') {
            return <Navigate to="/login" replace />;
        }
    }

    return children; // If none of the above, render the intended route
};

export default ProtectedRoute