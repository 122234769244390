import React, { useState, useEffect } from 'react';
import './signup.css'; // Import your CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import NavBar from './navbar';
import WelcomePage from './welcomePage';

const SignUp = () => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [isPasswordValid, setIsPasswordValid] = useState(null);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        // Check if passwords match whenever the password input changes
        setPasswordMatch(e.target.value === confirmPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        // Check if passwords match whenever the confirm password input changes
        setPasswordMatch(e.target.value === password);
    };

    const validatePassword = () => {
        // Minimum length check
        const isLengthValid = password.length >= 6;
        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const numberRegex = /[0-9]/;
        const specialCharRegex = /[!@#$%^&*]/;

        const hasUppercase = uppercaseRegex.test(password);
        const hasLowercase = lowercaseRegex.test(password);
        const hasNumber = numberRegex.test(password);
        const hasSpecialChar = specialCharRegex.test(password);

        return isLengthValid && hasUppercase && hasLowercase && hasNumber && hasSpecialChar;
    };
    // const isPasswordValid = validatePassword();



    useEffect(() => {
        let timeoutId;

        const validate = () => {
            setIsPasswordValid(null); // Reset validation status to null

            timeoutId = setTimeout(() => {
                // Validate password after 500 milliseconds of inactivity
                const isValid = validatePassword();
                setIsPasswordValid(isValid);
            }, 10);
        };

        // Clear timeout on each password change
        clearTimeout(timeoutId);

        if (password || confirmPassword) {
            validate();
        }

        // Cleanup function to clear the timeout
        return () => clearTimeout(timeoutId);
    }, [password, confirmPassword]);

    return (

        <div>
            <NavBar />
            <div className='layout'>
            <div>
                <WelcomePage/>
            </div>
            
            <div className="signup-container">
                

                    <div className="signup-card">
                        <h2 className="signup-heading">Sign Up</h2>
                        <div className='inputs'>
                            <div className="input-container">
                                <label>Name</label>
                                <input type="text" placeholder="Enter your name" />
                            </div>
                            <div className="input-container">
                                <label>Email</label>
                                <input type="email" placeholder="Enter your email" />
                            </div>
                            <div className="input-container">
                                <label>Phone</label>
                                <input type="text" placeholder="Enter your phone number" />
                            </div>
                            <div className="input-container">
                                <label>Password</label>
                                <div className="input-with-icon">
                                    <input type={showPassword ? 'text' : 'password'} placeholder="Enter your password" value={password}
                                        onChange={handlePasswordChange} />
                                    <FontAwesomeIcon
                                        icon={showPassword ? faEyeSlash : faEye}
                                        className="icon"
                                        onClick={togglePasswordVisibility}
                                    />
                                </div>
                                {!isPasswordValid && isPasswordValid !== null && (
                                    <p style={{ color: 'red', marginTop: '5px', textAlign: 'left' }}>
                                        Password must contain at least 6 characters including an uppercase letter, a lowercase letter, a number, and a special character (!@#$%^&*)
                                    </p>
                                )}

                            </div>
                            <div className="input-container">
                                <label>Confirm Password</label>
                                <div className="input-with-icon">
                                    <input type={showPassword ? 'text' : 'password'} placeholder="Confirm your password" value={confirmPassword}
                                        onChange={handleConfirmPasswordChange} />
                                    <FontAwesomeIcon
                                        icon={showPassword ? faEyeSlash : faEye}
                                        className="icon"
                                        onClick={togglePasswordVisibility}
                                    />
                                </div>
                                {!passwordMatch && (
                                    <p style={{ color: 'red', marginTop: '5px' }}>Passwords do not match</p>
                                )}
                            </div>
                        </div>
                        <button className="signup-button">Sign Up</button>
                        <div className="other-login-options">
                            {/* <div className="other-login-button google">
                            <img src={google} alt="Logo" className="btn-logo" />
                            Login with Google
                        </div>
                        <div className="other-login-button windows">
                            <img src={windows} alt="Logo" className="btn-logo" />
                            Login with Windows
                        </div>
                        <div className="other-login-button slack">
                            <img src={slack} alt="Logo" className="btn-logo" />
                            Login with Slack
                        </div> */}
                        </div>

                        <p>Already have an account? <Link to="/" className="sign-in">Log In</Link></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
