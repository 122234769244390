

import axios from 'axios';
import React, { Component } from 'react';
import './linkedin.css';


class Linkedin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobData: [],
            jobLocation: '',
            jobType: 'full-time',
            company: '',
            jobRole: '',
            isLoading: false,
            formSubmitted: false,
            jobTags: [],
            currentPage: 1,
            itemsPerPage: 5,

        };
    }
    getIndexRange = () => {
        const { currentPage, itemsPerPage } = this.state;
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return { startIndex, endIndex };
    };
    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ isLoading: true, formSubmitted: true });

        let linkedinCookie = '';
        const url = 'https://onepgr.com/users/get_profile_attributes?onepgr_apicall=1&xhr_flag=1';
        const body = new FormData();
        body.append('id', '4991');

        try {
            const response = await axios.post(url, body);
            const parsedResponse = response.data;
            console.log("API Response:", response.data);

            if (parsedResponse && parsedResponse.response) {
                const userDetails = JSON.parse(parsedResponse.response);
                if (userDetails.api_keys) {
                    const apiKeys = JSON.parse(userDetails.api_keys);
                    if (apiKeys.linkedIn) {
                        linkedinCookie = apiKeys.linkedIn.cookie;
                        console.log('LinkedIn Cookie:', linkedinCookie);
                    }
                }
            }


            // Now make the next API call here, as linkedinCookie is set
            const apiUrl = 'https://crawl.onepgr.com:3000/getjobs';
            const jobRoles = this.state.jobTags.join(',');
            const { jobLocation, jobType, company, resultLimit } = this.state;

            const fetchResponse = await fetch(
                `${apiUrl}?jobRoles=${encodeURIComponent(jobRoles)}&location=${encodeURIComponent(jobLocation)}&type=${jobType}&company=${encodeURIComponent(company)}&resultLimit=${resultLimit}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'linkedinCookie': linkedinCookie

                    },
                }
            );

            if (fetchResponse.ok) {
                const data = await fetchResponse.json();
                this.setState({ jobData: data.jobs });
            } else {
                this.setState({ jobData: [] });
            }
        } catch (error) {
            console.error('An error occurred:', error);
            this.setState({ jobData: [] });
        } finally {
            this.setState({ isLoading: false });
        }
    };

    handleTagsChange = (e) => {
        const { value } = e.target;
        const tagsArray = value.split(',').map(tag => tag.trim()); // Split input by comma to get an array of tags
        this.setState({ jobTags: tagsArray });
    };

    downloadCSV = () => {
        const { jobData } = this.state;

        if (jobData.length === 0) {
            alert('No data to export.');
            return;
        }

        const headers = Object.keys(jobData[0]);
        const csvContent =
            headers.join(',') +
            '\n' +
            jobData.map((row) => {
                return headers.map((fieldName) => {
                    return JSON.stringify(row[fieldName]).replace(/"/g, '');
                }).join(',');
            }).join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'jobData.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };
    handleResultLimitChange = (e) => {
        const { value } = e.target;
        this.setState({ resultLimit: value });
    };


    //    
    render() {
        console.log("Rendering Linkedin Component");
        const { jobData, isLoading, formSubmitted, currentPage, itemsPerPage } = this.state;
        const { startIndex, endIndex } = this.getIndexRange();
        return (
            <div className='linkedin-job-search'>
                <h1 className='linkedinHead'>Linkedin Job Search</h1>
                <form id='jobSearchForm' onSubmit={this.handleSubmit}>
                    <div className='linkedin-form-location'>
                        <label htmlFor="location">Location</label>
                        <input
                            className='linkedin-input'
                            type="text"
                            id="location"
                            name="jobLocation"
                            value={this.state.jobLocation}
                            onChange={this.handleInputChange}
                            placeholder="Search by country, state or city."
                            required
                        />
                    </div>
                    {/* <br /> */}
                    <div className='linkedin-form-type'>
                        <label htmlFor="jobType">Type</label>
                        <select
                            className="jobType"
                            name="jobType"
                            value={this.state.jobType}
                            onChange={this.handleInputChange}
                        >
                            <option value="full-time">Full Time</option>
                            <option value="part-time">Part Time</option>
                            <option value="contract">Contract</option>
                        </select>
                    </div>
                    {/* <br /> */}

                    {/* <label htmlFor="company">Company:</label>
                    <input
                        id="companies"
                        name="company"
                        value={this.state.company}
                        onChange={this.handleInputChange}
                        placeholder='Enter Company'
                        required
                    /> */}
                    <div className='linkedin-form-tags'>
                        <label htmlFor="jobTags">Keyword</label>
                        <input

                            className='linkedin-input'
                            id="jobTags"
                            name="jobTags"
                            value={this.state.jobTags.join(',')}
                            onChange={this.handleTagsChange}
                            placeholder='Enter keywords separated by commas'
                            required
                        />
                    </div>
                    <div className='linkedin-form-limit'>
                        <label htmlFor="resultLimit">No of jobs</label>
                        <div className="dropdown-container">
                            <select
                                className="resultLimit"
                                id="resultLimit"
                                name="resultLimit"
                                value={this.state.resultLimit}
                                onChange={this.handleResultLimitChange}
                            >
                                <option value="15">15</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="75">75</option>
                                <option value="100">100</option>

                            </select>
                        </div>
                    </div>
                    <div className='linkedin-form-button'>
                        <button type="submit" className='linkedin-button'>Search Jobs</button>
                    </div>
                </form>
                <div className="job-table-container">
                    {isLoading ? (
                        <div className="loading-spinner">

                        </div>

                    ) : this.state.jobData.length > 0 ? (
                        <div className="job-table-wrapper">
                            <button className='export-csv' onClick={this.downloadCSV}>Export as CSV</button>
                            <table className="job-table">
                                <thead>
                                    <tr>
                                        <th className="equal-width">Company</th>
                                        <th className="equal-width">Job Role</th>
                                        <th className="equal-width">Location</th>
                                        <th className="equal-width">Job Link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {jobData.slice(startIndex, endIndex).map((job, index) => (
                                        <tr key={index}>
                                            <td className="equal-width overflow-ellipsis" title={job.Company}>{job.Company}</td>
                                            <td className="equal-width overflow-ellipsis" title={job.Title}>{job.Title}</td>
                                            <td className="equal-width overflow-ellipsis" title={job.Location}>{job.Location}</td>
                                            <td className="equal-width overflow-ellipsis" title={job.Link}>
                                                <a href={job.Link} target="_blank" rel="noopener noreferrer" className='overflow-ellipsis'>
                                                    {job.Link}
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                              
                            </table>
                            <div className="pagination">
                                    <button
                                        onClick={() => this.setState({ currentPage: currentPage - 1 })}
                                        disabled={currentPage === 1}
                                    >
                                        Previous
                                    </button>
                                    <span>
                                        Page {currentPage} of {Math.ceil(jobData.length / itemsPerPage)}
                                    </span>
                                    <button
                                        onClick={() => this.setState({ currentPage: currentPage + 1 })}
                                        disabled={currentPage === Math.ceil(jobData.length / itemsPerPage)}
                                    >
                                        Next
                                    </button>
                                </div>

                        </div>
                    ) : formSubmitted ? (
                        <p className='error-message'>Error finding the jobs. check the linkedIn connection status </p>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default Linkedin;
