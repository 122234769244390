
import React, { useContext, useState } from 'react';
import './navbar.css'; 
import onepgrLogo from '../images /onepgr-logo.webp';
import { UserContext } from './context/userContext'; // Import the UserContext
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import profilepic from '../images /profilrpic.jpg'

const NavBar = () => {
    const { user, setUser } = useContext(UserContext); // Access user information from context
    const [showDropdown, setShowDropdown] = useState(false);
    const navigate = useNavigate(); // Hook for navigation

    const handleLogout = () => {
        setUser(null); // Clear the user data on logout
        localStorage.removeItem('user');
        navigate('/login'); // Redirect to login page
    };
   


    return (
        <nav className="navbar">
            <div className="logo">
                <img src={onepgrLogo} alt="Logo" />
            </div>

            {user && (
                <div className="profile-section">
                    <img  src={user.profilePic || profilepic}  alt="Profile" className="profile-pic" />
                    <span>{user.name}</span>
                    <button onClick={() => setShowDropdown(!showDropdown)} className="dropdown-button">▼</button>
                    {showDropdown && (
                        <div className="dropdown-menu">
                            <button onClick={handleLogout} className="logout-button">Logout</button>
                        </div>
                    )}
                </div>
            )}
        </nav>
    );
};

export default NavBar;
