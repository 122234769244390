import NavBar from "./navbar";
import { useNavigate } from 'react-router-dom';
import './home.css'
// import testImage from '../images /testimage.png'
import linkedin from '../images /linkedinJobSearch.png'
import typrform from '../images /typeform.png'
import kampaign from '../images /kampaign-ai.png'
import meetPage from '../images /meetPage.png'
import wfxoffice from '../images /wfxoffice.png'





const cardData = [
  { id: 1, image: meetPage, header: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', content: 'Meet.OnePgr' , path: 'https://meet.onepgr.com/'},
  { id: 2, image: kampaign  , header: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', content: 'Kampaign.ai', path: 'https://kampaign.ai/'},
  { id: 3, image: wfxoffice , header: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', content: 'Wfxoffice', path: 'https://wfxoffice.com/' },
  { id: 4, image: linkedin, header: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', content: 'LinkedIn Job Search', path: '/home/linkedin'},
  { id: 5, image: typrform, header: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', content: 'Type Form', path: 'https://onepgr.typeform.com/to/m1yRa4' },
  { id: 6, image: linkedin, header: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', content: 'Company Lobby', path: '/home/companyLobby' },
  // Add more card data as needed
];

const Card = ({ image, header, content , path  }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (path.startsWith('http://') || path.startsWith('https://')) {
      window.location.href = path;
    } else {
      navigate(path);
    }
  };
  return (


    <div className="home-card" onClick={handleClick}>
      <img src={image} alt="Placeholder" />
      <div className='details'>
        <h5>{content}</h5>
        <h6>{header}</h6>
      </div>
    </div>

  );
};

const Home = () => {

  return (
    <div className="home-container">
      <NavBar />
        <div className="home-card-container">
          {cardData.map((card) => (
            <Card
              key={card.id}
              image={card.image}
              header={card.header}
              content={card.content}
              path={card.path}
            />
          ))}
         
        </div>
      
    </div>
    
  );
};

export default Home;
