import React from 'react';
import './welcomePage.css'; // Import your CSS file for styling

import linkedinImg from '../images /linkedinTool.png'

import wfxoffice from '../images /wfxoffice.png'
import kampaign from '../images /kampaign-ai.png'
import meetpgr from '../images /meetPage.png'

const WelcomePage = ({ handleCardClick }) => {
  return (
    <div className="card-container">
      <div className="row1">
        <div className="card" onClick={() => handleCardClick(1)}>
          <img src={wfxoffice} alt="Placeholder" />
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
        </div>
        <div className="card" onClick={() => handleCardClick(2)}>
          <img src={kampaign}  alt="Placeholder" />
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
      </div>
      <div className="row2">
        <div className="card" onClick={() => handleCardClick(3)}>
          <img src={meetpgr}  alt="Placeholder" />
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div className="card" onClick={() => handleCardClick(4)}>
          <img src={linkedinImg}  alt="Placeholder" />
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
