import React from 'react';
import './onePgr-page.css'; 
import testImage from '../../../images /testimage.png'


const cardData = [
  { id: 1, image: testImage, header: 'Sequence', content: 'Link 1' , path: ''},
  { id: 2, image: testImage, header: 'Parallel Dialer', content: 'Link 2',path: '' },
  { id: 3, image: testImage, header: 'Video Messenger', content: 'Link 3',path: '' },
  { id: 4, image: testImage, header: 'LinkedIn', content: 'Link 4',path: '' },
  { id: 5, image: testImage, header: 'xyz', content: 'Link 5',path: '' },
  // Add more card data as needed
];

const Card = ({ image, header, content }) => {
  return (
  
       
    <div className="onePgr-card">
      <img src={image} alt="Placeholder" />
      <div className='onePgr-details'>
      <h2>{header}</h2>
       
      </div>
    </div>

  );
};

const onePgr = () => {
  return (
    <div className='onePgr-header'>
        <h1> OnePgr </h1>
    <div className="onePgr-card-container">
      {cardData.map((card) => (
        <Card
          key={card.id}
          image={card.image}
          header={card.header}
          content={card.content}
        />
      ))}
    </div>
    </div>
  );
};

export default onePgr;
