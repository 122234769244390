import React, { useState } from 'react';
import './login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import NavBar from './navbar';
import Notification from './notification';
import { useNavigate } from 'react-router-dom';
import WelcomePage from './welcomePage';
import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from './context/userContext';



const Login = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
        loginError: '',
        loginSuccess: '',
    });


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }
    const { setUser } = useContext(UserContext);

    const handleLogin = async () => {

        try {

            const url = 'https://onepgr.com/sessions/create3?xhr_flag=1'
            

            const body = new FormData();
            body.append('login', loginData.email);
            body.append('password', loginData.password);
            body.append('clientname', '11');
            body.append('clientappid', '22');
            body.append('clientappkey', '33');
            body.append('service_name', 'app2');

            // const response = await axios.post(url, body);
            axios.post(url, body)
                .then(response => {
                    const parsedResponse = JSON.parse(response.data.response);
                    console.log("Parsed Response:", parsedResponse);

                    // Check if the parsed response has success status and a session token
                    if (parsedResponse.success === "0" && parsedResponse.session_token) {
                        const userData = {
                            name: parsedResponse.user_login, // or parsedResponse.user_email
                            profilePic: parsedResponse.user_profile_img, // Use actual response data
                        };
                        handleLoginSuccess(userData);
                        setLoginData({
                            ...loginData,
                            loginSuccess: 'Successfully logged in.',
                            loginError: ''
                        });
                        setTimeout(() => {
                            navigate('/home');
                        }, 3000);

                    } else {
                        setLoginData({ ...loginData, loginError: 'Username or password is incorrect.' });
                        setTimeout(() => {
                            setLoginData({ ...loginData, loginError: '' });
                        }, 5000);
                    }
                })
                .catch(error => {
                    console.error('Error during login:', error);
                    setLoginData({
                        ...loginData,
                        loginError: 'An error occurred during login. Please try again later.',
                    });
                    setTimeout(() => {
                        setLoginData({ ...loginData, loginError: '' });
                    }, 5000);
                });
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };
    const handleLoginSuccess = (userData) => {
        console.log("User data before setting to localStorage:", userData);
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));

    };


    const handleCardClick = () => {


        const emailInput = document.querySelector('.input-container input[type="text"]');
        const passwordInput = document.querySelector('.input-container input[type="password"]');
        if (emailInput) {
            emailInput.style.borderColor = 'red';
            setTimeout(() => {
                emailInput.style.borderColor = ''; // Reset the border color after 5 seconds
            }, 5000);
        }
        if (passwordInput) {
            // Resetting the password input border color to its initial state if needed
            passwordInput.style.borderColor = 'red'; // Empty string restores default
            setTimeout(() => {
                passwordInput.style.borderColor = ''; // Reset the border color after 5 seconds
            }, 5000);
        }

    };
    return (
        <div>
            <NavBar />
            <div className='page-align'>
                <div>
                    <WelcomePage handleCardClick={handleCardClick} />
                </div>

                <div className="login-container">
                    <h1>Accelerate your sales with OnePgr</h1>
                    {/* <h3>Please login to get hands-on with the tools.</h3> */}

                    <div className="login-card">
                        <h2 className="login-heading">Login</h2>
                        <div className='inputs'>
                            <div className="input-container">
                                <label>Email <span>(or username)</span></label>
                                <div className="input-with-icon">
                                    <input type="text" placeholder="Enter your email or username" value={loginData.email}
                                        onChange={(e) => setLoginData({ ...loginData, email: e.target.value })} />
                                </div>
                            </div>
                            <div className="input-container">
                                <label className='label'>Password</label>
                                <div className="input-with-icon">
                                    <input type={showPassword ? 'text' : 'password'} placeholder="Enter your password" value={loginData.password}
                                        onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                                    />
                                    <FontAwesomeIcon
                                        icon={showPassword ? faEyeSlash : faEye}
                                        className="icon"
                                        onClick={togglePasswordVisibility}
                                    />
                                </div>
                            </div>
                        </div>
                        <button className="login-button" onClick={handleLogin}>Log in</button>
                        <a href="#" className="forgot-password">Forgot password?</a>
                        <p className='dont'>Don't have an account? <Link to="https://meet.onepgr.com/signup" className="sign-up">Sign Up</Link></p>
                    </div>
                </div>
                {loginData.loginError && (
                    <Notification type="error" message={loginData.loginError} />
                )}
                {loginData.loginSuccess && (
                    <Notification type="success" message={loginData.loginSuccess} />
                )}

            </div>
        </div>
    );
};

export default Login;
