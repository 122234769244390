
import './App.css';
import Login from './components/login';
import { UserProvider } from './components/context/userContext'; // Adjust the path as necessary
import ProtectedRoute from './components/protectedRoute/protectedRoute'
import NavBar from './components/navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home';
import SignUp from './components/signup';
import { Navigate } from 'react-router-dom';
import Linkedin from './components/linkedin-job-search/linkedin';
import CompanyLoby from './components/company-loby/compnayLobby';
import OnePgr from './components/company-loby/OnePgr-page/onePgr-page';

function App() {
  return (
      <Router>
          <UserProvider>
          <NavBar />
              <Routes>
                  <Route path="/" element={<Navigate to="/login" />} />
                  <Route path="/login" element={<ProtectedRoute><Login /></ProtectedRoute>} />
                  <Route path="/signup" element={<ProtectedRoute><SignUp /></ProtectedRoute>} />
                  <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                  <Route path="/home/linkedin" element={<Linkedin />} />
                  <Route path="/home/companyLobby" element={<CompanyLoby />} />
                  <Route path="/home/companyLobby/onepgr" element={<OnePgr />} />
                  <Route path="*" element={<Navigate to="/" />} />
                 
              </Routes>
          </UserProvider>
      </Router>
  );
}
export default App;
