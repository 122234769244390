import React from 'react';
import './companyLobby.css'
import testImage from '../../images /testimage.png'
import { useNavigate } from 'react-router-dom';

const cardData = [
  { id: 1, image: testImage, header: 'OnePgr', content: 'Link 1', path: '/home/companyLobby/onepgr' },
  { id: 2, image: testImage, header: 'Company 2', content: 'Link 2', path: '/companyPage2' },
  { id: 3, image: testImage, header: 'Company 3', content: 'Link 3', path: '/companyPage3' },
  { id: 4, image: testImage, header: 'Company 4', content: 'Link 4', path: '/companyPage4' },
  { id: 5, image: testImage, header: 'Company 5', content: 'Link 5', path: '/companyPage5' },
  // Add more card data as needed
];

const Card = ({ image, header, content, path }) => {

  const navigate = useNavigate();
  const handleClick = () => {
    if (path.startsWith('http://') || path.startsWith('https://')) {
      window.location.href = path;
    } else {
      navigate(path);
    }
  };
  return (


    <div className="company-card" onClick={handleClick}>
      <img src={image} alt="Placeholder" />
      <div className='company-details'>
        <h2>{header}</h2>
        {/* <a href={path}>{content}</a> */}
      </div>
    </div>

  );
};

const CompanyLoby = () => {
  return (
    <div className='company-header'>
      <h1> Companies </h1>
      <div className="company-card-container">
        {cardData.map((card) => (
          <Card
            key={card.id}
            image={card.image}
            header={card.header}
            path={card.path}
            content={card.content}
          />
        ))}
      </div>
    </div>
  );
};

export default CompanyLoby;
